import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Login from '../components/Auth/Login'
import Register from '../components/Auth/Register'

const Auth = () => {
  return (
    <>
    <section>
        <div className='row'>
            <Register />
          <Login />
        </div>
    </section>
    </>
  )
}

export default Auth