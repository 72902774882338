import React, { useCallback, useEffect, useRef, useState } from "react";
import Navs from "../../components/Admin/Navs";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import {
  addProduct,
  getProfile,
  fetchProducts,
  addBulkProduct,
  deleteProduct,
} from "../../features/producerSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner1 from "../../components/Spinner1";
import { toast } from "react-toastify";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactPaginate from "react-paginate";
import { usePDF } from "react-to-pdf";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const Products = () => {
  const dispatch = useDispatch();
  //payloads
  const { profile, products } = useSelector((state) => state.producer);
  const { user } = useSelector((state) => state.auth);

  //states
  const [productName, setProduct] = useState("");
  const [manufacturedDate, setProductDate] = useState("");
  const [expiryDate, setExpiry] = useState("");
  const [note, setNote] = useState("");
  const [producerId, setProducer] = useState("");
  const [productFile, setProductFile] = useState("");
  const [executed, setExecuted] = useState(false);
  const userId = user.id;

  const [currentPage, setCurrentPage] = useState(1);
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  //fetch profile
  useEffect(() => {
    async function fetchData() {
      await dispatch(getProfile());
    }
    fetchData();
  }, [dispatch]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setProducer(profile.response[0].id);
    setShow(true);
  };

  const [showUpload, setShowUpload] = useState(false);
  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => {
    setProducer(profile.response[0].id);
    setShowUpload(true);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleAddProduct = () => {
    let data = {
      productName,
      manufacturedDate,
      expiryDate,
      note,
      producerId,
      userId,
    };
    dispatch(addProduct(data))
      .unwrap()
      .then((response) => {
        setShow(false);
        setExecuted(!executed);
        toast.success(response.message);
      })
      .catch(toast.error);
  };

  //uploading bulk products
  const handleAddProductBulk = () => {
    const formData = new FormData();
    formData.append("productFile", productFile);
    formData.append("producerId", producerId);
    formData.append("userId", userId);

    // Dispatch the action with the formData
    dispatch(addBulkProduct(formData))
      .then(() => {
        setShowUpload(false);
        toast.success("Product Added");
        setExecuted((prevState) => !prevState);
      })
      .catch((error) => toast.error(error.message));
  };
  const productDelete = (id) => {
    try {
      dispatch(deleteProduct(id))
        .unwrap()
        .then((response) => {
          toast.success(response.message);
          setExecuted(!executed);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  //fetch the products
  useEffect(() => {
    async function fetchPros() {
      // Wait for the profile data to be loaded
      if (!profile) return;

      const pids = profile.response[0].id;
      await dispatch(fetchProducts({ producerId: pids, page: currentPage }));
    }
    fetchPros();
  }, [dispatch, executed, profile]);

  if (!profile || !products) return <Spinner1 />;

  return (
    <>
      <Navs />
      <section className="bg-light pageSection">
        <Container className="pt-5">
          <Row>
            <Col md={3} sm={12}>
              <Card className="dashboardCard border-0 w-100 h-100">
                <div className="card-body">
                  <p className="text-primary">Total Products</p>
                  <h4>
                    <span>{products.totalItems}</span>{" "}
                  </h4>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 pt-3">
            <Col md={12} sm={12}>
              <div className="btn-group float-end">
                <Button
                  className="btn btn-primary text-warning  mb-3 mx-2"
                  onClick={handleShowUpload}
                >
                  Upload Product
                </Button>
                <Button
                  className="btn btn-danger text-white mb-3"
                  onClick={handleShow}
                >
                  Add Product
                </Button>
                <Button
                  onClick={() => toPDF()}
                  className="btn btn-dark text-white mb-3 mx-2"
                >
                  Download PDF
                </Button>
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={targetRef.current}
                >
                  <button className="btn btn-danger text-white mb-3">
                    {" "}
                    Export excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
              <Card className="dashboardCard border-0" ref={targetRef}>
                <div className="card-body">
                  <h5 className="text-primary mb-3">Products</h5>
                  <table
                    className="table table-hover table-striped table-responsive table-bordered text-primary"
                    width={100}
                  >
                    <thead>
                      <tr>
                        <th className="p-3">Product Name</th>
                        <th className="p-3">Produced Date</th>
                        <th className="p-3">Expiry Date</th>
                        <th className="p-3">Serial Number</th>
                        <th className="p-3">Note</th>
                        <th className="p-3">QR link</th>
                        <th className="p-3">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products &&
                        products.data.map((items) => (
                          <tr key={items.product.id}>
                            <td>{items.product.productName}</td>
                            <td>
                              {moment(items.product.manufacturedDate).format(
                                "YYYY-MM-DD"
                              )}
                            </td>
                            <td className="p-3">
                              {moment(items.product.expiryDate).format(
                                "YYYY-MM-DD"
                              )}
                            </td>
                            <td>{items.product.serialNumber}</td>
                            <td>{items.product.note}</td>
                            <td>
                              <a href={items.image} target="_blank">
                                {items.image}
                              </a>
                            </td>
                            <td>
                              {/* <EditIcon style={{ cursor: "pointer" }} /> */}
                              <DeleteForeverIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => productDelete(items.product.id)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>{" "}
                  <ReactPaginate
                    pageCount={products.totalPages}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    forcePage={currentPage}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link text-warning"}
                    nextLinkClassName={"page-link text-primary"}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">
                  Product Name
                </label>
                <input
                  type="text"
                  placeholder="Name of product"
                  className="form-control"
                  onChange={(e) => setProduct(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">
                  Manufactured Date
                </label>
                <input
                  type="date"
                  placeholder="Manufactured Date"
                  className="form-control"
                  onChange={(e) => setProductDate(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">
                  Expiry Date
                </label>
                <input
                  type="date"
                  placeholder="Expiry Date"
                  className="form-control"
                  onChange={(e) => setExpiry(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">Note</label>
                {/* <textarea
                  className="form-control"
                  onChange={(e) => setNote(e.target.value)}
                ></textarea> */}

                <ReactQuill
                  theme="snow"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>

              <input
                type="hidden"
                defaultValue={profile.response[0].id}
                value={producerId}
                onChange={(e) => setProducer(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddProduct}>
              Save Changes
            </Button>
          </Modal.Footer>
        </>
      </Modal>

      <Modal show={showUpload} onHide={handleCloseUpload}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Upload Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="form-group mb-3">
                <div className="mb-3">
                  <label for="formFile" className="form-label">
                    Upload Logo
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept=".xlsx"
                    onChange={(e) => setProductFile(e.target.files[0])}
                  />
                </div>
                <small>Upload your excel file .xlsx</small>
              </div>

              <input
                type="hidden"
                defaultValue={profile.response[0].id}
                value={producerId}
                onChange={(e) => setProducer(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpload}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddProductBulk}>
              Save Changes
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default Products;
