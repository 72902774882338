import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, fetchSearchs } from "../../features/producerSlice";
import Spinner1 from "../Spinner1";
const { FormatMoney } = require("format-money-js");
const CardTabs = ({ profile }) => {
  var nf = new Intl.NumberFormat();
  const fm = new FormatMoney({
    decimals: 2,
  });

  const producerId = profile.response[0].id;
  const dispatch = useDispatch();
  //fetch products payload
  const { products, searchs } = useSelector((state) => state.producer);

  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchProducts({ producerId }));
      await dispatch(fetchSearchs({ producerId }));
    }
    fetchData();
  }, [dispatch]);

  if (!searchs || !products) return <Spinner1 />;
  return (
    <>
      <Row>
        <Col md={3} sm={12}>
          <Card className="dashboardCard border-1 w-100 h-100">
            <div className="card-body">
              <p className="text-muted">Wallet Balance</p>
              <h4>
                &#8358;<span>{nf.format(profile.response[0].wallet)}</span>{" "}
              </h4>
              
            </div>
          </Card>
        </Col>

        <Col md={3} sm={12}>
          <Card className="dashboardCard border-1 w-100 h-100">
            <div className="card-body">
              <p className="text-muted">Total Spent</p>
              <h4>
                &#8358;<span>{nf.format(profile.response[0].walletSpent)}</span>{" "}
              </h4>
              
            </div>
          </Card>
        </Col>

        <Col md={3} sm={12}>
          <Card className="dashboardCard border-1 w-100 h-100">
            <div className="card-body">
              <p className="text-muted">Total Products</p>
              <h4>
                <span>{products.data.count}</span>{" "}
              </h4>
              <p className="text-danger"></p>
            </div>
          </Card>
        </Col>

        <Col md={3} sm={12}>
          <Card className="dashboardCard border-1 w-100 h-100">
            <div className="card-body">
              <p className="text-muted">Total Searches</p>
              <h4>
                <span>{searchs.data.count}</span>{" "}
              </h4>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CardTabs;
