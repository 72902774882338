import React, { useEffect, useRef, useState } from "react";
import Layout from "../Layout";
import axios from "axios";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany, searchImageProduct, searchImageProductName } from "../../features/generalSlice";
import { toast } from "react-toastify";

const ImageSearch = () => {
  const { company, productImageName } = useSelector((state) => state.general);
  const { user } = useSelector((state) => state.auth);

  const [show, setShow] = useState(false);


  const [producerId, setProducer] = useState("");
  const [userLocationLat, setLocationLat] = useState(null);
  const [userLocationLong, setLocationLong] = useState(null);
  const [searchResult, setResult] = useState(null);
  const users = user ? user.email : "guest";
  const [capturedImage, setCapturedImage] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [productId, setProductId] = useState(false)
  const [loading, setLoading] = useState(false);
  

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchCompany());
    }
    fetchData();
  }, [dispatch]);

   // Handle brand selection
   const handleBrandSelect = (producerId) => {
    setProducer(producerId);
    dispatch(searchImageProductName(producerId)) // Fetch product names based on selected brand
  };


    //to show html added to desc
    function UnsafeComponent({ html }) {
      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }



    const handleImageUpload = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("image", capturedImage);
        formData.append("producerId", producerId);
        formData.append("users", users);
        formData.append("productId", productId);
  
        const response = await dispatch(searchImageProduct(formData));
        const data = response.payload; // Accessing the value directly from the resolved promise
        setResult(data)
        setShowResult(true);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };


  return (
    <>
           <section className="bg-light text-muted search_area">
        <Container className="d-flex flex-column align-item-center justify-content-center">
          <Row className="w-100">
            <Col md={3} sm={12}>
              <label className="mb-2">Select your brand</label>
              <select
                className="form-select form-control mb-3 p-3"
                onChange={(e) => handleBrandSelect(e.target.value)}
              >
                <option value={""}>Select a brand</option>
                {company &&
                  company.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.companyName}
                    </option>
                  ))}
              </select>
            </Col>

            {producerId && (
        <Col md={3} sm={12}>
          <label className="mb-2">Select product name</label>
          <select
            className="form-select form-control mb-3 p-3"
            onChange={(e) => setProductId(e.target.value)}
          >
            <option value={""}>Select product name</option>
            {productImageName &&
              productImageName.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.productName}
                </option>
              ))}
          </select>
        </Col>
      )}
            <Col md={4} sm={12}>
              <label className="mb-2">Image Upload</label>
              <input
                type="file"
                accept="image/*"
                className="form-control p-3"
                onChange={(e) => setCapturedImage(e.target.files[0])}
              />
              </Col>
              <Col md={2} sm={12}>

              {capturedImage && (
                <img
                  src={URL.createObjectURL(capturedImage)}
                  alt="Uploaded Image"
                  className="mt-3 img-fluid"
                  width={300}
                  height={300}
                />
              )}
            </Col>
            <Col md={12} sm={12}>
            <Button
          className="btn btn-primary"
          onClick={handleImageUpload}
          disabled={!capturedImage || !producerId || loading}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            "Upload and Verify"
          )}
        </Button>
            </Col>
          </Row>
          {showResult && (
            <Row className="justify-content-center mt-3">
              {searchResult.searchStatus === "successful" ? (
                <>

                  <div className="col-4 mt-5 justify-content-center">
                    {/* Display the original image here */}
                    <img
                      src="/images/original.png"
                      alt="Original"
                      className="img-fluid"
                    />
                     <div> <UnsafeComponent html={searchResult.data.note} />  </div>
                  </div>
                </>
              ) : searchResult.searchStatus === "failed" ? (
                <>
                  <div className="col-4 mt-5 justify-content-center">
                    <img
                      src="/images/fake.png"
                      alt="Fake"
                      width="200"
                      height="200"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </Row>
          )}
        </Container>
      </section>

    </>
  );
};

export default ImageSearch;
