import React, { useEffect } from 'react'
import Spinner1 from '../Spinner1'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../../features/producerSlice'
import { NavLink, useNavigate } from 'react-router-dom'
import { logout } from '../../features/authSlice'

const Navs = () => {
  const {profile} = useSelector((state)=>state.producer)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
    async function fetchData() {
      await dispatch(getProfile())
    }
    fetchData()
  },[dispatch])

  const logouts = () =>{
    dispatch(logout()).then(()=>{
      navigate('./')
    })
  }
 
const backend= process.env.REACT_APP_END

//console.log(profile.response[0].companyName)

  if(!profile)
  return(<Spinner1 />)

  return (

<div>
<nav className="navbar navbar-expand-lg bg-primary py-3">
  <div className="container">
    <NavLink className="navbar-brand" to="/dashboard">
     
      <img src={profile.image} width="60"/>
      
     
    </NavLink>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarText">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink
            className="nav-link text-white"
            activeClassName="active"
            exact
            to="/dashboard"
          >
            Home
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link text-white"
            activeClassName="active"
            to="/wallet"
          >
            Wallets
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link text-white"
            activeClassName="active"
            to="/products"
          >
            Products
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link text-white"
            activeClassName="active"
            to="/imageProduct"
          >
            Products with Image
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link text-white"
            activeClassName="active"
            to="/productSearch"
          >
            Searches
          </NavLink>
        </li>
      </ul>
      <ul className="nav-item dropdown navbar-nav  mb-2 mb-lg-0" >
        <li className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle text-white"
            to="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Hi {profile.response[0].companyName}
          </NavLink>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a className="dropdown-item text-danger" href="#">
                Profile
              </a>
            </li>
            <li>
              <a className="dropdown-item text-danger" onClick={logouts}>
                Logout 
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
</div>
  )
}

export default Navs