import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Spinner1 from '../Spinner1'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProducts } from '../../features/producerSlice'
import moment from 'moment'

const RecentProducts = ({profile}) => {

  const producerId = profile.response[0].id
     const dispatch = useDispatch()
//fetch products payload
const {products} = useSelector((state)=>state.producer)
const pageSize= 10

const handleFetchProducts = async () => {
  await dispatch(fetchProducts({ pageSize, producerId }));
};

useEffect(() => {
  handleFetchProducts();
}, [producerId, pageSize]);

   

  if(!profile || !products)
  return(<Spinner1 />)
  var x = 0;
  return (
    <>
    <Row className='py-4'>
        <Col md={9} sm={12}>
            <Card className='w-100 h-100 dashboardCard border-1 border-danger'>
                <div className='card-body'>
                  <table className='table table-hover table-striped table-bordered'>
                    <thead>
                      <tr>
                      <th>S/N</th>
                        <th className='p-3'>Product Name</th>
                        <th className='p-3'>Produced Date</th>
                        <th className='p-3'>Expiry Date</th>
                        <th className='p-3'>Serial Number</th>
                        <th className='p-3'>QR Image</th>
                        </tr>
                    </thead>
                    <tbody>
                      {products.data.map((items)=>(
                        <tr key={items.key}>
                          <td>{++x}</td>
                          <td className='p-3'>{items.product.productName}</td>
                          <td className='p-3'>{moment(items.product.manufacturedDate).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.product.expiryDate}</td>
                          <td className='p-3'>{items.product.serialNumber}</td>
                          <td className='p-3'><img src={items.image} alt={items.product.productName} width={80} /></td>
                        </tr>
                      ))}
                        
                    </tbody>

                  </table>
                </div>
                
            </Card>
        </Col>

        <Col md={3} sm={12}>
            <Card className='w-100 h-100 dashboardCard border-1 border-danger'>
                <div className='card-body'>
                  <h5 className='text-muted mb-3'>Top Location Search</h5>  
                  {/* <ul className='list-group list-group-flush'>
                    <li class="list-group-item">Lagos</li>
                    <li class="list-group-item">Onitsha</li>
                    <li class="list-group-item">Jos</li>
                  </ul> */}
                 
                </div>
                
            </Card>
        </Col>



    </Row>

    </>
  )
}

export default RecentProducts