import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Pricing from './pages/Admin/Pricing';
import Auth from './pages/Auth';
import Dashboard from './pages/Admin/Dashboard';
import Products from './pages/Admin/Products';
import Wallets from './pages/Admin/Wallets';
import ProductSearch from './pages/Admin/ProductSearch';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSetup from './components/Auth/ProfileSetup';
import './custom.scss'
import { useSelector } from 'react-redux';
import Protected from './components/Auth/Protected';
import ImageSearch from './components/Search/ImageSearch';
import ImageProduct from './pages/Admin/ImageProduct';
import NewHome from './pages/NewHome';
import ForgotPassword from './components/Auth/ForgotPassword';
import ChangePassword from './components/Auth/ChangePassword';
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';
import About from './pages/About';
import MainPage from './pages/MainPage';

function App() {

  const {user} = useSelector((state)=>state.auth)
  return (
    <>
    <ToastContainer />
   
   <Routes> 
   
    <Route path='/' element={<MainPage />} />
    <Route path='/login' element={<Login />} />
    <Route path='/verify' element={<NewHome />} />
    <Route path='/imageSearch' element={<ImageSearch />}/>
    <Route path='/signup' element={<Register />} />
    <Route path='/forgotPassword' element={<ForgotPassword />} />
    <Route path='/changePassword' element={<ChangePassword />} />
    <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
    <Route path='/aboutUs' element={<About/>} />
  
    <Route path='/enroll' element={<Protected authenticated={user}><Pricing /></Protected>} />
    <Route path='/auth' element={<Auth />} />
    <Route path='/dashboard' element={  <Protected authenticated={user}><Dashboard /></Protected>} />
    <Route path='/products' element={<Protected authenticated={user}><Products /></Protected>} />
    <Route path='/wallet' element={<Protected authenticated={user}><Wallets /></Protected>} />
    <Route path='/productSearch' element={<Protected authenticated={user}><ProductSearch /></Protected>} />
    <Route path='/profileSetup' element={<Protected authenticated={user}><ProfileSetup /></Protected>} />
    <Route path='/imageProduct' element={<Protected authenticated={user}><ImageProduct /></Protected>}/>

    </Routes>
    </>
  );
}

export default App;
