import React from 'react'
import Nav from './Nav'

const Header = () => {

  return (
    <>
    <Nav />
    </>
  )
}

export default Header