import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { addUser } from "../../features/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Nav from "../Header/Nav";
import Footer from "../Footer/Footer";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setType] = useState("");
  const [confirmPass, setConfirmPass] = useState("")
  const [isSpinning, setIsSpinning] = useState(false);

  const dispatch = useDispatch();

  const handleSignup = () => {
    setIsSpinning(true);
    let data = {
      firstName,
      lastName,
      email,
      password,
      userType,
    };

    if(password != confirmPass){
      return toast.error('Password doesnot match');
    }
    dispatch(addUser(data))
      .unwrap()
      .then(() => {
        toast.warning("check your email to confirm your email");
        setIsSpinning(false);
      })
      .catch((error) => {
        toast.error(error);
        setIsSpinning(false);
      });
  };

  return (
    <>
    <Nav />
      <section>
        <Container>
         
          <Row>
            <Col md={8} sm={12} className="offset-md-2" style={{ minHeight: "calc(50vh)" }}>
           
              
         
                    
                <div
                  className="d-flex flex-column justify-content-center p-5"
                  style={{
                    marginTop: "5%",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                >
                   <img src="/images/authecityLogoLong.png" className="img-fluid pb-5" width={350} />
                  <h3 className="display-5 fw-bold text-primary">Start Here</h3>
                  <p className="text-muted">Enter details to login</p>
                  <div className="mt-5">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control p-3"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control p-3"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3 ">
                      <input
                        type="email"
                        className="form-control p-3"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="password"
                        className="form-control p-3"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="password"
                        className="form-control p-3"
                        placeholder="Confrim Password"
                        onChange={(e) => setConfirmPass(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-check-inline mb-3">
                      <input
                        className="form-check-input mx-2"
                        type="radio"
                        value={'regular'}
                        name="flexRadioDefault"
                        onChange={(e) => setType(e.target.value)}
                        id="flexRadioDefault1"
                        checked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Consumer
                      </label>
                    </div>
                    <div className="form-check-inline mb-3">
                      <input
                        className="form-check-input mx-2"
                        type="radio"
                        value={'producer'}
                        onChange={(e) => setType(e.target.value)}
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Producer
                      </label>
                    </div>

                    <button
                      className={`btn btn-primary btn-block py-4 w-100 ${
                        isSpinning ? "spin" : ""
                      }`}
                      onClick={handleSignup}
                    >
                      {isSpinning ? (
                        <i className="fa fa-spinner fa-spin"></i> // You can use any spinner icon library
                      ) : (
                        "Start Now"
                      )}
                    </button>
                  </div>
                </div>
              
            </Col>
          
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Register;
