import React from 'react'
import Layout from '../components/Layout'
import { Carousel } from 'react-bootstrap'

const MainPage = () => {
  return (
   <Layout>
    <Carousel fade>
      <Carousel.Item>
        <a href='/verify'>
        <img src='slider/authecity2.jpg' alt='slider1' className='img-fluid'/>
        </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href='/verify'>
      <img src='slider/authecity1.jpg' alt='slider1' className='img-fluid'/>
       </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href='/signup'>
      <img src='slider/authecity3.jpg' alt='slider1' className='img-fluid'/>
      </a>
      </Carousel.Item>
    </Carousel>
    </Layout>
  )
}

export default MainPage