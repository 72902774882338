import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changePassword, checkMail, login } from "../../features/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Nav from "../Header/Nav";
import Footer from "../Footer/Footer";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //login states
  const [pass2, setPassword2] = useState("");
  const [password, setPassword] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [showMessage, setShowMessage] = useState(false);

  const handleChange = () => {
    let data = {
      email,
      token,
      password
    };
    if(password != pass2){
        return toast.error('Password doesnot match')
    }
    dispatch(changePassword(data))
      .unwrap()
      .then((response) => {
        navigate('/login')
        toast.success(response.message)
        })   
      .catch(toast.error);
  };

  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <Row>
            <Col
              md={8}
              sm={12}
              className="offset-md-2 justify-content-center align-items-center"
            >
              <div
                className="d-flex flex-column justify-content-center p-5"
                style={{
                  marginTop: "5%",
                  marginLeft: "10%",
                  marginRight: "10%",
                  marginBottom: "10%",
                  minHeight: "calc(50vh)"
                }}
              >
                <h3 className="display-5 fw-bold text-primary">Change Password</h3>
                <p className="text-muted">Please enter your new password</p>
                <div className="form-group mb-3 mt-5">
                  <input
                    type="password"
                    className="form-control p-3"
                    placeholder="New Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="form-group mb-3">
                  <input
                    type="password"
                    className="form-control p-3"
                    placeholder="Confirm Password"
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </div>
               

                <button
                  className="btn btn-primary  btn-block py-3"
                  onClick={handleChange}
                >
                  Change Password
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ChangePassword;
