import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import '../Search/search.css'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompany, searchProduct } from '../../features/generalSlice';
import Spinner1 from '../Spinner1';
import { toast } from 'react-toastify';


const SearchProduct = () => {
 const{company} = useSelector((state)=>state.general)
  const{user} = useSelector((state)=>state.auth)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocationLong(position.coords.longitude);
        setLocationLat(position.coords.latitude);
      });
    }
  }, []);
   
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [producerId, setProducer] = useState('')
  const [serialNumber, setSerial] = useState('')
  const [userLocationLat, setLocationLat] = useState(null)
  const [userLocationLong, setLocationLong] = useState(null)
  const [searchResult, setResult] = useState(null)
  const users = user ? user.email : 'guest'

  //states to display products


const handleSearchProduct = (e) => {
  e.preventDefault()
  let data = {
    producerId,
    serialNumber,
    userLocationLat,
    userLocationLong,
    users
  }
  dispatch(searchProduct(data)).unwrap().then((response)=>{
   setResult(response)
  }).catch(toast.error)
}

  const dispatch = useDispatch()

  useEffect(()=>{
    async function fetchData(){
      await dispatch(fetchCompany())
    }
    fetchData()
  },[dispatch])

  //to show html added to desc
  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  console.log(searchResult)

  if(!company)
  return(
    <Spinner1 />
  )


  return (
    <>
    

    <section className='bg-light text-muted search_area'>
    <Container className='d-flex flex-column align-item-center justify-content-center'>
      <Row className='w-100'>
        <Col md={5} sm={12}>
        <label className='mb-2'>Select your brand</label>
        <select className='form-select form-control mb-3 p-3' onChange={(e)=>setProducer(e.target.value)}>
            <option defaultValue={''}>Select a brand</option>
            {company.map((items)=>
              <option value={items.id} key={items.id}>{items.companyName}</option>
            )}
        </select>        
        </Col>
        <Col md={7} sm={12}>
        <label className='mb-2'>Input your serial number</label>
        <input className='form-control mb-3 p-3' type='text' placeholder='please input your code here' onChange={(e)=>setSerial(e.target.value)} />
        </Col>

        <Col md={12} sm={12}>
          <button className='btn btn-primary btn-block float-end w-25 p-2' onClick={handleSearchProduct}>Verify</button> 
        </Col>
      </Row>
     {searchResult && (
      <Row className="justify-content-center">
        {searchResult.status === 'Original' ? 
        
        <>
        <div className="col-4">
                  <img src='/images/original.png' alt='Original' />
                </div>
        <div className="col-4 mt-5">
        <div> <UnsafeComponent html={searchResult.data[0].note} />  </div>
              </div>
             

                </>
              
   : searchResult.status === 'Fake' ?  <>
       
  <div className="col-4 mt-5 justify-content-center">
        
            <img src='/images/fake.png' alt='Original' width='200' />
          </div>

          </>
         : ''}
         
      </Row>
     )}
    </Container>
    </section>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Hi Guest</p> 

            <h5>Product Name : <span className='lead text-muted'>Recline</span></h5>
            <h5>Manufactured Date : <span className='lead text-muted'>12/01/2023</span></h5>
            <h5>Expiry Date : <span className='lead text-muted'>12/12/2023</span></h5>
            <h5>Producer Name : <span className='lead text-muted'>Dangote</span></h5>
            <h5>Note : <span className='lead text-muted'>This product appears in two different colors, green and yellow only</span></h5>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>


    </>
  )
}

export default SearchProduct