import {createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"


// Get user from localstorage
const user = JSON.parse(localStorage.getItem('userDetails'))


const initialState = {
  user: user ? user : null,    isLoading: false,
    isLoggedIn: false,
  }

  //get main link
const url = process.env.REACT_APP_BACKEND

//change Password
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (data, thunkAPI) => {
    try{
       const response = await axios.post(`${url}/auth/changePassword`, data)
    if (response.data) {
      localStorage.setItem('userDetails', JSON.stringify(response.data))
    }
    return response.data
  }catch(error){
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
   
  });
  //check email
  export const checkMail = createAsyncThunk(
    'auth/checkMail',
    async (data, thunkAPI) => {
      try{
         const response = await axios.post(`${url}/auth/checkEmail`, data)
      if (response.data) {
        localStorage.setItem('userDetails', JSON.stringify(response.data))
      }
      return response.data
    }catch(error){
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
     
    });

  //login 
export const login = createAsyncThunk(
  'auth/login',
  async (data, thunkAPI) => {
    try{
       const response = await axios.post(`${url}/auth/login`, data)
    if (response.data) {
      localStorage.setItem('userDetails', JSON.stringify(response.data))
    }
    return response.data
  }catch(error){
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
   
  });

  export const addUser = createAsyncThunk(
    'auth/signup',
    async(data, thunkAPI) => {
      try{
        const response = await axios.post(`${url}/auth/signUp`, data)
        return response.data

      }catch(error){
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
        
      }
    }
  )

 

// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  await localStorage.removeItem('userDetails')
});


  export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
  
    },
    extraReducers:  (builder) => {
      (builder)
    
    .addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.error = null;
    })
    .addCase(login.pending, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.error = action.payload;
    })
    .addCase(login.rejected, (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.error = null;
    })
    .addCase(logout.fulfilled, (state)=>{
      state.isLoggedIn = false;
      state.user = null;
    })
    
  }  
})


export default authSlice.reducer
