import React, { useEffect, useState } from "react";
import Navs from "../../components/Admin/Navs";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import {
  getProfile,
  addImageProduct,
  fetchImageProducts,
  getSingleImageProduct,
  deleteImageProduct,
} from "../../features/producerSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner1 from "../../components/Spinner1";
import { toast } from "react-toastify";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactPaginate from "react-paginate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ImageProduct = () => {
  const backendUrl = process.env.REACT_APP_END;
  const dispatch = useDispatch();
  //payloads
  const { profile, singleImageProduct, imageProducts } = useSelector(
    (state) => state.producer
  );
  const { user } = useSelector((state) => state.auth);

  //states
  const [productName, setProduct] = useState("");
  const [manufacturedDate, setProductDate] = useState("");
  const [expiryDate, setExpiry] = useState("");
  const [note, setNote] = useState("");
  const [producerId, setProducer] = useState("");
  const [executed, setExecuted] = useState(false);
  const [image, setImage] = useState(null);
  const userId = user.id;

  const [currentPage, setCurrentPage] = useState(1);

  //fetch profile
  useEffect(()=>{
    async function fetchData() {
      await dispatch(getProfile());
    }
    fetchData();
  },[dispatch])


  //   //open modal for editing
  //   const editOpen = (values) => {
  //     setShow(true);
  //     setId(values);
  //   };

  //   useEffect(() => {
  //     if (show && id) {
  //       dispatch(getSingleImageProduct(id));
  //     }
  //   }, [dispatch, id, show]);

  //   useEffect(() => {
  //     if (singleImageProduct) {
  //       setExpiry(singleImageProduct.expiryDate);
  //       setProduct(singleImageProduct.productName);
  //       setProductDate(singleImageProduct.manufacturedDate);
  //       setNote(singleImageProduct.note);
  //       setImage(singleImageProduct.path)
  //     }
  //   }, [singleImageProduct]);

  //update product
  const handleAddProduct = () => {
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("manufacturedDate", manufacturedDate);
    formData.append("expiryDate", expiryDate);
    formData.append("note", note);
    formData.append("image", image);
    formData.append("userId", userId);
    formData.append("producerId", producerId);
    dispatch(addImageProduct(formData))
      .unwrap()
      .then((response) => {
        setShow(false);
        setExecuted(!executed);
        toast.success(response.message);
      })
      .catch((response) => {
        toast.error(response);
      });
  };

  const deleteProduct = (id) => {
    try {
      dispatch(deleteImageProduct(id))
        .unwrap()
        .then((response) => {
          toast.success(response.message);
          setExecuted(!executed);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  //   const updateProductImage = () => {
  //     const data = new FormData();
  //     data.append('productName', productName);
  //     data.append('manufacturedDate', manufacturedDate);
  //     data.append('expiryDate', expiryDate);
  //     data.append('note', note);
  //     data.append('image', image);
  //     data.append('id', id);

  //     dispatch(updateProductImage(data))
  //       .unwrap()
  //       .then((response) => {
  //         setShow(false);
  //         setExecuted(!executed);
  //         toast.success(response.message);
  //       })
  //       .catch((error) => {
  //         toast.error(error.message);
  //       });
  //   };

  //fetch the products
  useEffect(() => {
    async function fetchPros() {
      // Wait for the profile data to be loaded
      if (!profile) return;

      const pids = profile.response[0].id;
      await dispatch(
        fetchImageProducts({ producerId: pids, page: currentPage })
      );
    }
    fetchPros();
  }, [dispatch, executed, profile]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setProducer(profile.response[0].id);
    setShow(true);
  };
  
  console.log(profile)

  if (!profile) return <Spinner1 />;

  console.log(imageProducts)
  

  return (
    <>
      <Navs />
      <section className="bg-light pageSection">
        <Container className="pt-5">
          <Row>
            <Col md={3} sm={12}>
              <Card className="dashboardCard border-0 w-100 h-100">
                <div className="card-body">
                  <p className="text-primary">Total Products</p>
                  <h4>
                    <span>{imageProducts && imageProducts.totalItems}</span>{" "}
                  </h4>
                  <p className="text-danger">
                    Recently Added <small className="text-danger">150</small>
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 pt-3">
            <Col md={12} sm={12}>
              <Button
                className="btn btn-danger text-white float-end mb-3"
                onClick={handleShow}
              >
                Add Product
              </Button>
              <Card className="w-100 h-100 dashboardCard border-0">
                <div className="card-body">
                  <h5 className="text-primary mb-3">Products</h5>
                  <table className="table table-hover table-striped table-bordered text-primary">
                    <thead>
                      <tr>
                        <th className="p-3">Product Name</th>
                        <th className="p-3">Produced Date</th>
                        <th className="p-3">Expiry Date</th>
                        <th className="p-3">Note</th>
                        <th className="p-3">Image</th>
                        <th className="p-3">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {imageProducts &&
                        imageProducts.data.map((items) => (
                          <tr key={items.id}>
                            <td className="p-3">{items.product.productName}</td>
                            <td className="p-3">
                              {moment(items.manufacturedDate).format(
                                "YYYY-MM-DD"
                              )}
                            </td>
                            <td className="p-3">
                              {moment(items.product.expiryDate).format("YYYY-MM-DD")}
                            </td>
                            <td className="p-3">{items.product.note}</td>
                            <td className="p-3">
                              
                                <img
                                  src={items.image}
                                  className="img-fluid"
                                  alt={`${items.product.productName} Image`}
                                  width={200}
                                />
                             
                            </td>
                            <td className="p-3">
                              {/* <a href={`editProductImage?id=${items.id}`} className="text-dark">
          <EditIcon style={{ cursor: "pointer" }} />
        </a> */}

                              <DeleteForeverIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteProduct(items.product.id)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>{" "}
                  <ReactPaginate
                    pageCount={Math.ceil(
                      imageProducts && imageProducts.totalPages
                    )}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    forcePage={currentPage}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link text-warning"}
                    nextLinkClassName={"page-link text-primary"}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">
                  Product Name
                </label>
                <input
                  type="text"
                  placeholder="Name of product"
                  className="form-control"
                  onChange={(e) => setProduct(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">
                  Manufactured Date
                </label>
                <input
                  type="date"
                  placeholder="Manufactured Date"
                  className="form-control"
                  onChange={(e) => setProductDate(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">
                  Expiry Date
                </label>
                <input
                  type="date"
                  placeholder="Expiry Date"
                  className="form-control"
                  onChange={(e) => setExpiry(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label lead fs-6 text-danger">Note</label>
                {/* <textarea
                  className="form-control"
                  onChange={(e) => setNote(e.target.value)}
                ></textarea> */}
               <ReactQuill
  theme="snow"
  value={note}
  onChange={setNote}
/>

              </div>

              <div className="form-group mb-3">
                <label htmlFor="formFile" className="form-label">
                  Upload Product Image
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>

              <input
                type="hidden"
                value={producerId}
                onChange={(e) => setProducer(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button variant="primary" onClick={handleAddProduct}>
              Save Changes
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default ImageProduct;
