import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from "react-bootstrap";
import Navs from '../../components/Admin/Navs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFake, fetchSearchs, getProfile } from '../../features/producerSlice';
import ReactPaginate from 'react-paginate';
import Spinner1 from '../../components/Spinner1';
import moment from 'moment';
const ProductSearch = () => {
  const dispatch = useDispatch();
  //payloads
  const { profile, searchs, fake } = useSelector((state) => state.producer);
  const { user } = useSelector((state) => state.auth);

  //states
  const [executed, setExecuted] = useState(false);
  const userId = user.id;


  //setting current page
  const [currentPage, setCurrentPage] = useState(1);

    //fetch profile
    useEffect(()=>{
      async function fetchData() {
        await dispatch(getProfile());
      }
      fetchData();
    },[dispatch])


    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };

   
   
  
  //fetch searchs payload  
  const handleFetchSearchs = async () => {
    if (!profile) return;

    const producerId = profile.response[0].id

  await dispatch(fetchSearchs({ page: currentPage, producerId }));
  await dispatch(fetchFake({producerId}))
  };
  
  useEffect(() => {
  handleFetchSearchs();
  }, [currentPage, profile]);
var x = 0;

  if (!profile || !fake || !searchs) return <Spinner1 />;
  return (
    <>
     <Navs />
      <section className="bg-light pageSection">
      <Container className="pt-5">
        <Row>
        <Col md={3} sm={12}>
            <Card className='dashboardCard border-0 w-100 h-100'>
                <div className='card-body'>
                   <p className='text-danger'>Counterfiet Search</p> 
                   <h4><span>{fake.data.count}</span> </h4> 
                   <p className='text-warning'>last search <small className='text-muted'>14 Jan 2023</small></p>
                </div>               
            </Card>

        </Col>


        <Col md={3} sm={12}>
            <Card className='dashboardCard border-0 w-100 h-100'>
                <div className='card-body'>
                   <p className='text-primary'>Originial Search</p> 
                   <h4><span>{searchs.data.count}</span> </h4> 
                   <p className='text-warning'>last search <small className='text-muted'>14 Jan 2023</small></p>
                </div>
               
            </Card>

        </Col>

       

        </Row>
        <Row className="mt-3 pt-3">
        <Col md={12} sm={12}>
            <Card className='w-100 h-100 dashboardCard border-0'>
                <div className='card-body'>
                  <h5 className='text-primary mb-3'>Searched Products</h5>  
                  <table className='table table-hover table-striped table-bordered text-primary'>
                    <thead>
                      <tr>
                    <th className='p-3'>S/N</th>
                        <th className='p-3'>Product Name</th>
                        <th className='p-3'>Produced Date</th>
                        <th className='p-3'>Expiry Date</th>
                        <th className='p-3'>Serial Number</th>
                        <th className='p-3'>Searched Date</th>
                        <th className='p-3'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                      {searchs.data.rows.map((items)=>(
                        <tr key={items.id}>
                          <td className='p-3'>{++x}</td>
                          <td className='p-3'>{items.pids== null ? '' :items.pids.productName}</td>
                          <td className='p-3'>{items.pids== null ? '' :moment(items.pids.manufacturedDate).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.pids== null ? '' :moment(items.pids.expiryDate).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.pids== null ? '' :items.pids.serialNumber}</td>
                          <td className='p-3'>{items.pids== null ? '' :moment(items.createdAt).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.pids== null ? '' :items.searchStatus}</td>

                        </tr>
                      ))}
                        
                        
                    </tbody>

                  </table>
                  <ReactPaginate
                    pageCount={searchs.totalPages}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    forcePage={currentPage}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link text-warning"}
                    nextLinkClassName={"page-link text-primary"}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                  />
                </div>
                
            </Card>
        </Col>

        </Row>
      </Container>
      </section>
    </>
  )
}

export default ProductSearch