import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import Navs from '../../components/Admin/Navs';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, fundWallet, fetchTransactions } from '../../features/producerSlice';
import Spinner1 from '../../components/Spinner1';
import PaystackPop from '@paystack/inline-js';
import { toast } from 'react-toastify';
import moment from 'moment';


const Wallets = () => {
  const {profile, transacts} = useSelector((state)=>state.producer)
  const dispatch = useDispatch()

  

  var nf = new Intl.NumberFormat();

  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [amount, setAmount] = useState('')
    const [payGateway, setGateway] = useState('')
    const [executed, setExecuted] = useState(false)

   

  useEffect(()=>{
    async function fetchData() {
      await dispatch(getProfile());
    }
    fetchData();
  },[dispatch, executed])

  // const producerId =  profile.response[0].id 
//   console.log(producerId)
useEffect(() => {
  async function fetchTrans() {
    // Wait for the profile data to be loaded
    if (!profile) return;

    const producerId = profile.response[0].id;
    await dispatch(fetchTransactions({ producerId }));
  }
  fetchTrans();
  },[dispatch, executed, profile])

  const handlePayStack = () => {
    setShow(false)
    const paystack = new PaystackPop();

    paystack.newTransaction({

      // other params
      key: 'pk_test_ae5545ec20aba5219eea5238c3d0b508ad4824b8', // Replace with your public key
      email: profile.response[0].email,
      amount: amount * 100,
      ref: 'Auth'+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      label: "Wallet funding",
     onSuccess: (response) => { 
      // let message = 'Payment complete! Reference: ' + response.reference;
      let transId = response.reference
      // let description = response.label
    // Payment complete! Reference: transaction.reference 
    let data = {
      producerId:profile.response[0].id,
      amount:amount, 
      transactionId:transId, 
      transactionStatus:'successful', 
      description:"wallet Funding", 
      payGateway:payGateway
    }
    dispatch(fundWallet(data)).unwrap().then((response)=>{
      setExecuted(!executed)
      toast.success("Payment Succesful")
    }).catch(toast.error)
    
  },

  onCancel: () => {

    // user closed popup
    alert('Window closed.');

  }

});

  }


  


  if(!profile || !transacts)
  return(<Spinner1 />) 
  
console.log(transacts)
 var x  = 0
  return (
    <>
    <Navs />
      <section className="bg-light pageSection">
      <Container className="pt-5">
        <Row>
        <Col md={3} sm={12}>
            <Card className='dashboardCard border-0 w-100 h-100'>
                <div className='card-body'>
                   <p className='text-muted'>Wallet Balance</p> 
                   <h4>&#8358;<span>{nf.format(profile.response[0].wallet)}</span> </h4> 
                   <p className='text-success'>last loaded <small className='text-muted'>14 Jan 2023</small></p>
                </div>               
            </Card>

        </Col>


        <Col md={3} sm={12}>
            <Card className='dashboardCard border-0 w-100 h-100'>
                <div className='card-body'>
                   <p className='text-muted'>Total Spent</p> 
                   <h4>&#8358;<span>{nf.format(profile.response[0].walletSpent)}</span> </h4> 
                   <p className='text-danger'>spent today <small className='text-muted'>&#8358; 10</small></p>
                </div>
               
            </Card>

        </Col>

        </Row>
        <Row className="mt-3 pt-3">
        <Col md={12} sm={12}>
            <Card className='w-100 h-100 dashboardCard border-0'>
                <div className='card-body'>
                <Button className='btn btn-primary float-end' onClick={handleShow}>Fund Wallet</Button>
                  <h5 className='text-muted mb-3'>Transactions</h5>  
                  
                  <table className='table table-hover table-striped table-bordered'>
                    <thead>
                      <th className='p-3'>S/N</th>
                        <th className='p-3'>Amount</th>
                        <th className='p-3'>Date</th>
                        <th className='p-3'>Trans Id</th>
                        <th className='p-3'>Status</th>
                        <th className='p-3'>Pay Method</th>
                        <th className='p-3'>Pay Method</th>
                    </thead>
                    <tbody>
                    {transacts.data.rows.map((items)=>(
                        <tr key={items.id}>
                          <td className='p-3'>{++x}</td>
                          <td className='p-3'>{items.amount}</td>
                          <td className='p-3'>{moment(items.createdAt).format('MM-DD-YYYY')}</td>
                          <td className='p-3'>{items.transactionId}</td>
                          <td className='p-3'>{items.transactionStatus}</td>
                          <td className='p-3'>{items.payMethod}</td>
                          <td className='p-3'>{items.payGateway}</td>

                        </tr>
                      ))}
                        
                    </tbody>

                  </table>
                </div>
                
            </Card>
        </Col>

        </Row>
      </Container>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fund Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group mb-3'>
            <label className='form-label'>Amount</label>
             <input type='number' className='form-control' onChange={(e)=>setAmount(e.target.value)} />
          </div>

          <div className='form-group'>
            <label className='form-label'>Payment Gateway</label>
             <select className='form-select form-control' onChange={(e)=>setGateway(e.target.value)}>
              <option value={''}>Select Payment Gateway</option>
              <option value="paystack">PayStack</option>
              <option value="flutter">FlutterWave</option>
             </select>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePayStack}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default Wallets