import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"

const initialState = {
    company: null,
    searchResult:null,
    productImageName: null
}

//search product
export const searchProduct = createAsyncThunk(
    'user/searchProducts',
    async(data, thunkAPI)=>{
        try {
        const response = await axios.post(`${url}/users/searchProduct`, data)
        return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
        
    }
)

//search productImage
export const searchImageProduct = createAsyncThunk(
    'user/searchImageProducts',
    async(data, thunkAPI)=>{
        try {
        const response = await axios.post(`${url}/users/compareImage`, data)
        return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
        
    }
)


//get product image names
//search productImage
export const searchImageProductName = createAsyncThunk(
    'user/searchImageProductName',
    async(id, thunkAPI)=>{
        try {
        const response = await axios.get(`${url}/users/singleProduct/${id}`)
        return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
        
    }
)



//get job by Categories
export const fetchCompany = createAsyncThunk(
    'users/fetchCompany',
    async (_, thunkAPI) => {
    
      try {
        const response = await axios.get(`${url}/users/getCompany`)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
      }
  
    });
  

//get main link
const url = process.env.REACT_APP_BACKEND



export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        (builder)

        .addCase(fetchCompany.fulfilled, (state, action) => {
            state.company = action.payload
        })
        .addCase(searchProduct.fulfilled, (state, action)=>{
            state.searchResult=action.payload
        })
        .addCase(searchImageProductName.fulfilled, (state, action)=>{
            state.productImageName = action.payload
        })

    }
})


export default generalSlice.reducer

