import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Header from '../../components/Header/Header'

const Pricing = () => {
  return (
    <>
    <Header />
    <section className='bg-light p-5'>
        <Container>
        <div className="row">
            <div className="col-lg-12">
                <div className="title-box text-center">
                    <h3 className="title-heading mt-4 text-primary">Best Pricing Package </h3>
                    <p className="text-muted f-17 mt-3">Vivamus ac nulla ultrices laoreet neque mollis mi morbi
                        elementum mauris
                        sit amet arcu <br /> fringilla auctor In eleifend maximus nisi sed vulputate.</p>

                    <img src="images/home-border.png" height="15" className="mt-3" alt="" />
                </div>
            </div>
        </div>


        <Row className="mt-3 pt-4">
            <Col md={4} sm={12} className='px-3'>
                <Card className="card mt-4 border-0 p-5">
                    <div className='card-body'>
                    <i className="mdi mdi-account h1"></i>
                    <h4 className="f-20">Starter</h4>

                    <div className="mt-4 pt-2">
                        <p className="mb-2 f-18">Features</p>

                        <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2"></i><b>Unlimited</b>
                            Target Audience</p>
                        <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2"></i><b>1</b>
                            User Account</p>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2"></i><b>100+</b>
                            Video Tuts</p>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2"></i><b>Public</b>
                            Displays
                        </p>
                    </div>

                    <p className="mt-4 pt-2 text-muted">Semper urna veal tempus pharetra elit habisse platea dictumst.
                    </p>
                    <div className="pricing-plan mt-4 pt-2">
                        <h4 className="text-muted"><s> $9.99</s> <span className="plan pl-3 text-dark">$0 </span></h4>
                        <p className="text-muted mb-0">Per Month</p>
                    </div>


                    <div className="mt-4 pt-3">
                        <a href="/getStarted?pricing=starter" className="btn btn-primary btn-rounded btn-block">Start Here</a>
                    </div>
                    </div>
                </Card>
            </Col>

            <Col md={4} sm={12} className='px-3'>
                <Card className="card mt-4 border-0 p-5">
                    <div className='card-body'>
                    <i className="mdi mdi-account h1"></i>
                    <h4 className="f-20">Premium</h4>

                    <div className="mt-4 pt-2">
                        <p className="mb-2 f-18">Features</p>

                        <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2"></i><b>Unlimited</b>
                            Target Audience</p>
                        <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2"></i><b>1</b>
                            User Account</p>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2"></i><b>100+</b>
                            Video Tuts</p>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2"></i><b>Public</b>
                            Displays
                        </p>
                    </div>

                    <p className="mt-4 pt-2 text-muted">Semper urna veal tempus pharetra elit habisse platea dictumst.
                    </p>
                    <div className="pricing-plan mt-4 pt-2">
                        <h4 className="text-muted"><s> $9.99</s> <span className="plan pl-3 text-dark">$0 </span></h4>
                        <p className="text-muted mb-0">Per Month</p>
                    </div>


                    <div className="mt-4 pt-3">
                        <a href="/getStarted?pricing=premium" className="btn btn-primary btn-rounded btn-block">Start Here</a>
                    </div>
                    </div>
                </Card>
            </Col>


            <Col md={4} sm={12} className='px-3'>
                <Card className="card mt-4 border-0 p-5">
                    <div className='card-body'>
                    <i className="mdi mdi-account h1"></i>
                    <h4 className="f-20">Diamond</h4>

                    <div className="mt-4 pt-2">
                        <p className="mb-2 f-18">Features</p>

                        <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2"></i><b>Unlimited</b>
                            Target Audience</p>
                        <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2"></i><b>1</b>
                            User Account</p>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2"></i><b>100+</b>
                            Video Tuts</p>
                        <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2"></i><b>Public</b>
                            Displays
                        </p>
                    </div>

                    <p className="mt-4 pt-2 text-muted">Semper urna veal tempus pharetra elit habisse platea dictumst.
                    </p>
                    <div className="pricing-plan mt-4 pt-2">
                        <h4 className="text-muted"><s> $9.99</s> <span className="plan pl-3 text-dark">$0 </span></h4>
                        <p className="text-muted mb-0">Per Month</p>
                    </div>


                    <div className="mt-4 pt-3">
                        <a href="/getStarted?pricing=diamond" className="btn btn-primary btn-rounded btn-block">Start Here</a>
                    </div>
                    </div>
                </Card>
            </Col>

            
        </Row>
 
            
           
    </Container>
    </section>
    </>
  )
}

export default Pricing