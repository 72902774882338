import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { addCompany } from "../../features/producerSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ProfileSetup = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [logo, setLogo] = useState(null);
  const email = user.email;
  const userId = user.id;

  const handleAddCompany = () => {
    const formData = new FormData();
    formData.append('companyName', companyName);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('userId', userId);
    formData.append('logo', logo);

    dispatch(addCompany(formData))
      .unwrap()
      .then((response) => {
        navigate('/dashboard');
        toast.success(response);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <section>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: 'calc(100vh)' }}>
          <div className="mt-3">
            <h3 className="fw-bold">
              <span className="text-primary">Welcome</span>
              <span className="text-muted"> Kindly profile your Company</span>
            </h3>
          </div>

          <div className="mt-5">
            <div className="row">
              <div className="col-md-12 col-sm-12">
            <Card className="border-0 dashboardCard px-3" >
              <CardHeader>
                <h3>Company Profile</h3>
              </CardHeader>
              <div className="card-body">
                <Row>
                  <Col md={12} sm={12}>
                    <div className="form-group mb-3">
                      <label className="form-label">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={12} sm={12}>
                    <div className="form-group mb-3">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={12} sm={12}>
                    <div className="form-group mb-3">
                      <label className="form-label">Email</label>
                      <input type="text" className="form-control" value={email} readOnly />
                    </div>
                  </Col>
                  <Col md={12} sm={12}>
                    <div className="mb-3">
                      <label htmlFor="formFile" className="form-label">
                        Upload Logo
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => setLogo(e.target.files[0])}
                      />
                      <small>Not more than 2mb</small>
                    </div>
                  </Col>
                  <Button className="btn btn-primary btn-block" onClick={handleAddCompany}>
                    Continue
                  </Button>
                </Row>
              </div>
            </Card>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSetup;
