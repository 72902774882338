import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { checkMail, login } from "../../features/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Nav from "../Header/Nav";
import Footer from "../Footer/Footer";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //login states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const verifyParam = searchParams.get("verify");
  const [showMessage, setShowMessage] = useState(false);

  const handleLogin = () => {
    let data = {
      email
    };
    dispatch(checkMail(data))
      .unwrap()
      .then((response) => {
        toast.success(response.message)
        })   
      .catch(toast.error);
  };

  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <Row>
            <Col
              md={8}
              sm={12}
              className="offset-md-2 justify-content-center align-items-center"
            >
              <div
                className="d-flex flex-column justify-content-center p-5"
                style={{
                  marginTop: "5%",
                  marginLeft: "10%",
                  marginRight: "10%",
                  marginBottom: "10%",
                  minHeight: "calc(50vh)"
                }}
              >
                <h3 className="display-5 fw-bold text-primary">Forgot Password</h3>
                <p className="text-muted">Please enter your email</p>
                <div className="form-group mb-3 mt-5">
                  <input
                    type="email"
                    className="form-control p-3"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
               
               

                <button
                  className="btn btn-primary  btn-block py-3"
                  onClick={handleLogin}
                >
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ForgotPassword;
