import React from 'react'

const Footer = () => {
  return (
    <>
    <footer className="footer-07 bg-black py-5">
			<div className="container">
				<div className="row justify-content-center py-5">
					<div className="col-md-12 text-center pt-5">
						<h2 className="footer-heading"><a href="/" className="logo"><img src='/images/authecityLogoLong.png' className='img-fluid' width={250} /></a></h2>
						<p className="menu">
							<a href="#">Home</a>
							<a href="#">How it works</a>
							<a href="#">About</a>
							<a href="#">Contact</a>
							<a href="#">SignUp</a>
						</p>
						
					</div>
				</div>
				<div className="row mt-5 py-5">
					<div className="col-md-12 text-center py-5">
						<p className="copyright">
					  Copyright &copy; {new Date().getFullYear()} All rights reserved  <i className="ion-ios-heart" aria-hidden="true"></i> by <a href="/" target="_blank" className='text-decoration-none'>Authecity Systems LTD</a>
					  </p>
					</div>
				</div>
			</div>
		</footer>
      
    </>
  )
}

export default Footer
