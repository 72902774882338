import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFake, fetchSearchs } from '../../features/producerSlice'
import Spinner1 from '../Spinner1'
import moment from 'moment'

const RecentResult = ({profile}) => {

  const producerId = profile.response[0].id
  const dispatch = useDispatch()

//fetch searchs payload
const {searchs, fake} = useSelector((state)=>state.producer)
const pageSize= 10

const handleFetchSearchs = async () => {
await dispatch(fetchSearchs({ pageSize, producerId }));
await dispatch(fetchFake({producerId}))
};

useEffect(() => {
handleFetchSearchs();
}, [producerId, pageSize]);
var x = 0;

if(!searchs || !fake)
return (<Spinner1 />)

  return (
    <>
     <Row className='py-4'>
     <Col md={3} sm={12}>
            <Card className='dashboardCard border-0 w-100 h-25'>
                <div className='card-body'>
                   <p className='text-muted'>Total Counterfeit</p> 
                   <h4><span>{fake.data.count}</span> </h4> 
                   <p className='text-success'>last search<small className='text-muted'>14 Jan 2023</small></p>
                </div>               
            </Card>

        </Col>

        <Col md={9} sm={12}>
            <Card className='w-100 h-100 dashboardCard border-1 border-primary'>
                <div className='card-body'>
                  <h5 className='text-muted mb-3'>Recently Searched Products</h5>  
                  <table className='table table-hover table-striped table-bordered'>
                    <thead>
                      <tr>
                      <th className='p-3'>S/N</th>
                        <th className='p-3'>Product Name</th>
                        <th className='p-3'>Produced Date</th>
                        <th className='p-3'>Expiry Date</th>
                        <th className='p-3'>Serial Number</th>
                        <th className='p-3'>Searched Date</th>
                        <th className='p-3'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                      {searchs.data.rows.map((items)=>(
                        <tr key={items.id}>
                          <td className='p-3'>{++x}</td>
                          <td className='p-3'>{items.pids== null ? '' :items.pids.productName}</td>
                          <td className='p-3'>{items.pids== null ? '' :moment(items.pids.manufacturedDate).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.pids== null ? '' :moment(items.pids.expiryDate).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.pids== null ? '' :items.pids.serialNumber}</td>
                          <td className='p-3'>{items.pids== null ? '' :moment(items.createdAt).format('YYYY-MM-DD')}</td>
                          <td className='p-3'>{items.pids== null ? '' :items.searchStatus}</td>

                        </tr>
                      ))}
                        
                    </tbody>

                  </table>
                </div>
                
            </Card>
        </Col>

       



    </Row>

    </>
  )
}

export default RecentResult