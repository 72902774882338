import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { login } from "../../features/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Nav from "../Header/Nav";
import Footer from "../Footer/Footer";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //login states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const verifyParam = searchParams.get("verify");
  const [showMessage, setShowMessage] = useState(false);

  const handleLogin = () => {
    let data = {
      email,
      password,
    };
    dispatch(login(data))
      .unwrap()
      .then((response) => {
        if (response.userType === "regular") {
          navigate("/");
        } else if (response.userType === "producer") {
          if (response.profileSetup == 1) {
            navigate("/dashboard");
          } else {
            navigate("/profileSetup");
          }
          // console.log(response.profileSetup)
        }
      })
      .catch(toast.error);
  };

  return (
    <>
      <Nav />
      <header>
        {verifyParam ? (
          <div
            className="alert alert-info alert-dismissible fade show text-center"
            role="alert"
          >
            Your email has been verified successfully
            <i
              className="fa fa-times mx-3"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowMessage(false)}
            ></i>
          </div>
        ) : (
          ""
        )}
      </header>
      <section>
        <div className="container">
          <Row>
            <Col
              md={8}
              sm={12}
              className="offset-md-2 justify-content-center align-items-center"
            >
              <div
                className="d-flex flex-column justify-content-center p-5"
                style={{
                  marginTop: "5%",
                  marginLeft: "10%",
                  marginRight: "10%",
                  marginBottom: "10%",
                  minHeight: "calc(50vh)"
                }}
              >
                <h3 className="display-5 fw-bold text-primary">Welcome</h3>
                <p className="text-muted">Enter details to login</p>
                <div className="form-group mb-3 mt-5">
                  <input
                    type="email"
                    className="form-control p-3"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="password"
                    className="form-control p-3"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <a href="/forgotPassword" className="nav-link text-success mb-3">
                  Forgot Password
                </a>

                <button
                  className="btn btn-primary  btn-block py-3"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
