import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/authSlice'
import producerReducer from '../features/producerSlice'
import generalReducer from '../features/generalSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    producer:producerReducer,
    general:generalReducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})