import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"
//get main link
const url = process.env.REACT_APP_BACKEND
const initialState = {
    producer: null,
    profile: null,
    products: null,
    searchs:null,
    fake:null,
    transacts:null,
    imageProducts:null,
    singleProduct:null,
    singleImageProduct:null
}
//add bulk product
export const addBulkProduct = createAsyncThunk(
  'producer/addBulkProducts',
  async(data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(`${url}/producer/addProductExcel`, data, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
)
//get total products original
export const fundWallet= createAsyncThunk(
    'producer/fundWallet',
    async (data, thunkAPI) => {
     
      try {
        const token = thunkAPI.getState().auth.user.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.post(`${url}/producer/fundwallet`, data, config);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error));
      }
    }
  );

//get total products fake
export const fetchFake = createAsyncThunk(
    'producer/fetchFake',
    async ({ params, producerId }, thunkAPI) => {
      const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
      try {
        const token = thunkAPI.getState().auth.user.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${url}/producer/getProductFake/${producerId}?page=${page}&pageSize=${pageSize}`, config);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error));
      }
    }
  );
//get total products
export const fetchProducts = createAsyncThunk(
    'producer/fetchProducts',
    async ({ params, producerId }, thunkAPI) => {
      const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
      try {
        const token = thunkAPI.getState().auth.user.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${url}/producer/getProducts/${producerId}?page=${page}&pageSize=${pageSize}`, config);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error));
      }
    }
  );

  //get single product
  export const getSingleProduct = createAsyncThunk(
    'producer/getSingleProduct',
    async ( id, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${url}/producer/getSingleProduct/${id}`, config);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error));
      }
    }
  );

    //get single product
    export const getSingleImageProduct = createAsyncThunk(
      'producer/getSingleImageProduct',
      async ( id, thunkAPI) => {
       
        try {
          const token = thunkAPI.getState().auth.user.token;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.get(`${url}/producer/getSingleImageProduct/${id}`, config);
          return response.data;
        } catch (error) {
          return thunkAPI.rejectWithValue(extractErrorMessage(error));
        }
      }
    );

  //get total products with image
export const fetchImageProducts = createAsyncThunk(
  'producer/fetchImageProducts',
  async ({ params, producerId }, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.user.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${url}/producer/getImageProduct/${producerId}?page=${page}&pageSize=${pageSize}`, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

  //get total productSearches
export const fetchSearchs = createAsyncThunk(
    'producer/fetchSearchs',
    async ({ params, producerId }, thunkAPI) => {
      const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
      try {
        const token = thunkAPI.getState().auth.user.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${url}/producer/getProductSearch/${producerId}?page=${page}&pageSize=${pageSize}`, config);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error));
      }
    }
  );
  


//add product
export const addProduct = createAsyncThunk(
    "producer/addProduct",
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await axios.post(`${url}/producer/addProduct`, data, config)
            return response.data
            
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
    }
)

//add product with image verification
export const addImageProduct = createAsyncThunk(
  "producer/addImageProduct",
  async (data, thunkAPI) => {
      try {
          const token = thunkAPI.getState().auth.user.token
          const config = {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
          const response = await axios.post(`${url}/producer/productWithImage`, data, config)
          return response.data
          
      } catch (error) {
          return thunkAPI.rejectWithValue(extractErrorMessage(error))
      }
  }
)
//add a company
export const addCompany = createAsyncThunk(
    "producer/addCompany",
    async (data, thunkAPI) => {

        try {
            const token = thunkAPI.getState().auth.user.token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await axios.post(`${url}/producer/addCompany`, data, config)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
    }
)


//get profile
export const getProfile = createAsyncThunk(
    "producer/getProfile",
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            const id = thunkAPI.getState().auth.user.id
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await axios.get(`${url}/producer/getProfile/${id}`, config)
            return response.data
            
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
    }
)

//get total products original
export const fetchTransactions = createAsyncThunk(
    'producer/fetchTransactions',
    async ({ params, producerId }, thunkAPI) => {
      const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
      try {
        const token = thunkAPI.getState().auth.user.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${url}/producer/getTransactions/${producerId}?page=${page}&pageSize=${pageSize}`, config);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error));
      }
    }
  );

//update productImage
export const updateImageProduct = createAsyncThunk(
  'producer/updateImageProduct',
  async (data, thunkAPI) => {

    try {
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axios.post(`${url}/producer/updateImageProduct`, data, config)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
}
)



//delete productImage
export const deleteImageProduct = createAsyncThunk(
  'producer/deleteImageProduct',
  async (id, thunkAPI) => {

    try {
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axios.delete(`${url}/producer/deleteProductImage/${id}`,  config)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
}
)

//delete productImage
export const deleteProduct = createAsyncThunk(
  'producer/deleteProduct',
  async (id, thunkAPI) => {

    try {
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axios.delete(`${url}/producer/deleteProduct/${id}`,  config)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
}
)


export const producerSlice = createSlice({
    name: 'producer',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        (builder)

        .addCase(getProfile.fulfilled, (state, action) => {
            state.profile = action.payload
        })
       .addCase(fetchProducts.fulfilled, (state, action)=>{
        state.products = action.payload
       })
       .addCase(fetchSearchs.fulfilled, (state, action)=>{
        state.searchs = action.payload
       })
       .addCase(fetchFake.fulfilled, (state, action)=>{
        state.fake = action.payload
       })
       .addCase(fetchTransactions.fulfilled, (state, action)=>{
        state.transacts = action.payload
       })
       .addCase(fetchImageProducts.fulfilled, (state, action)=>{
        state.imageProducts = action.payload
       })
       .addCase(getSingleProduct.fulfilled, (state, action)=>{
        state.singleProduct = action.payload
       })
       .addCase(getSingleImageProduct.fulfilled, (state, action)=>{
        state.singleImageProduct = action.payload
       })

    }
})


export default producerSlice.reducer

