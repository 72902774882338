import React from 'react'
import Layout from '../components/Layout'
import { Container } from 'react-bootstrap'
import SearchProduct from '../components/Search/SearchProduct'
import ImageSearch from '../components/Search/ImageSearch'
import QrSearch from '../components/Search/QrSearch'

const NewHome = () => {
  return (
    <div>
      <Layout>
      <section className='bg-black p-5 text-white'>
    <Container className='d-flex align-item-center justify-content-center'>
        <h2 className='display-5 lead fw-bold'>Check the authenticity of your product</h2>
    </Container>
    </section>
        <Container>
        <div id="accordion" className='mt-5 py-5'>
  <div class="card">
    <div class="card-header bg-primary" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link text-decoration-none text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Verify by serial number
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
       <SearchProduct />
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-warning" id="headingTwo">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed text-decoration-none text-primary" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Verify by Image
        </button>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
      <ImageSearch />
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-danger" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed text-decoration-none text-white" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Verify by QR code
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
       <QrSearch />
      </div>
    </div>
  </div>
</div>
        </Container>
      </Layout>
    </div>
  )
}

export default NewHome
