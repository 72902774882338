import React, { useEffect } from 'react'
import './admin.css'
import Navs from '../../components/Admin/Navs'
import {Container } from 'react-bootstrap'
import CardTabs from '../../components/Admin/CardTabs'
import RecentProducts from '../../components/Admin/RecentProducts'
import RecentResult from '../../components/Admin/RecentResult'
import { fetchProducts, getProfile } from '../../features/producerSlice'
import { useDispatch, useSelector } from 'react-redux'
import Spinner1 from '../../components/Spinner1'

const Dashboard = () => {
  const {profile, products} = useSelector((state)=>state.producer)
  const dispatch = useDispatch()
  

  useEffect(()=>{
    async function fetchData() {
      await dispatch(getProfile())
    }
    fetchData()
  },[dispatch])

  
  if(!profile)
  return(<Spinner1 />)
  
  

  return (
    <div>
      <Navs />
      <section className='bg-light pt-5' style={{minHeight:'calc(100vh)'}}>
      <Container>
        <h4 className='lead fw-bold mb-4 text-danger'>Product Analytics</h4>
      <CardTabs profile={profile} />
      <RecentProducts profile={profile} />
      <RecentResult profile={profile} />

      </Container>
      </section>
    </div>
  )
}

export default Dashboard