import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../features/authSlice'
import { NavLink, useNavigate } from 'react-router-dom'

const Nav = () => {
  const{user}= useSelector((state)=>state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logouts = () =>{
    dispatch(logout()).then(()=>{
      navigate('./')
    })
  }
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-4">
  <div className="container">
    <a className="navbar-brand" href="/"><img src='/images/authecityLogoLong.png' className='img-fluid' width={200} alt='authecity logo' /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
  <li className="nav-item">
    <NavLink
      to="/"
      exact
      className="nav-link"
      activeClassName="active" // This class will be applied to the active link
    >
      Home
    </NavLink>
  </li>

  <li className="nav-item">
    <NavLink
      to="/verify"
      className="nav-link"
      activeClassName="active"
    >
      Verify your product
    </NavLink>
  </li>

  <li className="nav-item">
    <NavLink
      to="/aboutUs"
      className="nav-link"
      activeClassName="active"
    >
      About Us
    </NavLink>
  </li>

  <li className='"nav-item'>
    <NavLink to="/privacyPolicy"
    className="nav-link"
    activeClassName="active"
    >
      Privacy Policy
    </NavLink>

  </li>
  {/* <li className="nav-item">
    <NavLink
      to="/imageSearch"
      className="nav-link"
      activeClassName="active"
    >
      Search by Image
    </NavLink>
  </li> */}
</ul>

   
      <span className="navbar-text">
      {user ?   <li class="nav-item dropdown navbar-nav me-auto mb-2 mb-lg-0">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           Hi {user.firstName}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li><a class="dropdown-item" onClick={logouts}>Logout</a></li>
          </ul>
        </li> :
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <a className="nav-link" href="/login">Login</a> 
        </li> 
        <li className="nav-item">
        <a className="nav-link" href="/signup">Signup</a>
        </li>
      </ul>
      }
      </span>
    </div>
  </div>
</nav>
    </div>
  )
}

export default Nav