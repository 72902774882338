import React from 'react'
import Layout from '../components/Layout'
import { Col, Container, Row } from 'react-bootstrap'

const About = () => {
  return (
   
        <Layout>
            <Container>
                <Row className='py-5'>
               
                    <Col md={6} sm={12} className='px-3'>
                       <img src='images/authecity.png' alt='about us' className='img-fluid' /> 
                    </Col>
                  <Col md={6} sm={12} className='py-5 text-muted' style={{ fontWeight:'200px' }}>
                    <h4 className='text-primary mb-3'>About Us</h4>
                    <p className='fs-4'>   Authecity.com is owned and operated by Authecity Systems LTD, a technology company based in Nigeria. Authenticity Systems LTD also has international affiliations. 
We developed the technology which powers authecity.com and it was developed to address the need to challenges of counterfeiting faced by product manufacturers and distributors. 
Our technology evolves with the changing needs of the environment.

You can <a href='/contact'>contact </a> us if you have needs which our technology does not currently address adequately. 
</p>
                </Col>

               
              </Row>
             
                    
                
            </Container>
            
        </Layout>
      
  
  )
}

export default About
